import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import { Container, Row, Col } from 'reactstrap'

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Index({ data }) {
  const { edges: posts } = data.allMarkdownRemark
  const { coverImage } = data
  // console.log('index.js data', data)

  return (
    <Layout headerImage={coverImage.childImageSharp.fluid}>
      <SEO title="Main page" keywords={[`Tangerine Fix`, `coffee`]} />
      <Container>
        {posts
          .filter(post => post.node.frontmatter.title.length > 0)
          .map(({ node: post }) => {
            return (
              <Row css={css`
              padding-bottom: 2em;
              `}>
                <Col>
                  <div className="card" css={css`
                    border: none;
                  `}>
                    <Link
                      to={post.fields.slug}
                      className="card-link"
                      css={css`
                        text-decoration: none;
                        color: inherit;
                        &:hover {
                          text-decoration: none;
                          color: inherit;
                        }
                      `}
                    >
                      <div class="card-body">
                        {post.frontmatter.featuredImage && <Img css={css`
                                width: 25%;
                                float: left;
                                margin-right: 1em;
                              `} fluid={post.frontmatter.featuredImage.childImageSharp.fluid}/>}
                        <h5 class="card-title">{post.frontmatter.title}</h5>
                        <h6 class="card-subtitle mb-2 text-muted">{post.frontmatter.date}</h6>
                        <p class="card-text">{post.frontmatter.summary || post.excerpt}</p>
                      </div>
                    </Link>
                  </div>
                </Col>
              </Row>
            )
          })}
      </Container>
    </Layout>
  )
}


export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { fields: { collection: { eq: "posts" } } }
    ) {
      edges {
        node {
          excerpt
          id
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            summary
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 412) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    coverImage: file(
      relativePath: { regex: "/IMG_2350c/" }
    ) {
      childImageSharp {
        fluid(
          maxWidth: 1024
          quality: 80
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
